// menu model but needs refs from here
import { Ref, ref } from "vue";
import {
    EditRuleOptions,
    InsertRule,
    ViewEntry,
} from "@/composables/RulesView/RulesViewTypes";

// context menu checks
const isNewRule = (rule: ViewEntry | null) => rule?.newRule;
const isExisting = (rule: ViewEntry | null) => !rule?.newRule;

const canMoveUp = (rule: ViewEntry | null) => {
    return rule?.ruleIndex ?? -1 > 0;
};

const canMoveDown = (rule: ViewEntry | null) => {
    return (rule?.ruleIndex ?? 0) < (rule?.ruleSet.rules.length ?? 0) - 1;
};

export function useContextMenuModel(
    contextRule: Ref<ViewEntry | null>,
    isEditingPermitted: Ref<boolean>,
    cutCopyRule: Ref<ViewEntry | null>,
    moveRuleUp: Function,
    moveRuleDown: Function,
    deleteRule: Function,
    deleteRuleSet: Function,
    copyCutRule: Function,
    insertRule: Function,
): Ref<Array<any>> {
    return ref([
        {
            label: "Move rule up",
            icon: "pi pi-arrow-up",
            visible: () => isExisting(contextRule.value),
            disabled: () =>
                !isEditingPermitted.value || !canMoveUp(contextRule.value),
            command: () => moveRuleUp(contextRule.value),
        },
        {
            label: "Move rule down",
            icon: "pi pi-arrow-down",
            visible: () => isExisting(contextRule.value),
            disabled: () =>
                !isEditingPermitted.value || !canMoveDown(contextRule.value),
            command: () => moveRuleDown(contextRule.value),
        },
        {
            label: "Copy rule",
            icon: "pi pi-copy",
            visible: () => isExisting(contextRule.value),
            disabled: () => !isEditingPermitted.value,
            command: () => copyCutRule(contextRule.value, EditRuleOptions.COPY),
        },
        {
            label: "Cut rule",
            icon: "pi pi-file",
            visible: () => isExisting(contextRule.value),
            disabled: () => !isEditingPermitted.value,
            command: () => copyCutRule(contextRule.value, EditRuleOptions.CUT),
        },
        {
            label: "Insert rule after",
            icon: "pi pi-angle-double-down",
            disabled: () => !cutCopyRule.value,
            visible: () => isExisting(contextRule.value),
            command: () => insertRule(contextRule.value, InsertRule.AFTER),
        },
        {
            label: "Insert rule before",
            icon: "pi pi-angle-double-up",
            disabled: () => !cutCopyRule.value,
            visible: () => isExisting(contextRule.value) || cutCopyRule.value,
            command: () => insertRule(contextRule.value, InsertRule.BEFORE),
        },
        {
            label: "Delete rule",
            icon: "pi pi-trash",
            visible: () => isExisting(contextRule.value),
            disabled: () => !isEditingPermitted.value,
            command: () => deleteRule(contextRule.value),
        },
        {
            separator: true,
            visible: () =>
                isExisting(contextRule.value) || isNewRule(contextRule.value),
        },
        {
            label: "Delete rule set",
            icon: "pi pi-trash",
            visible: () =>
                isExisting(contextRule.value) || isNewRule(contextRule.value),
            disabled: () => !isEditingPermitted.value,
            command: () => deleteRuleSet(contextRule.value),
        },
    ]);
}
