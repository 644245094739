import Papa, { ParseResult } from "papaparse";

import { MassOnboardingEntry, RedirectEntry } from "@/model/redirect";

import _ from "lodash";

export function parseDomainsList(
    domainsList: File,
): Promise<MassOnboardingEntry[]> {
    return new Promise((resolve, reject) => {
        Papa.parse(domainsList, {
            delimiter: ";",
            complete: (results: ParseResult<string[]>) => {
                const parsed: string[][] = results.data;

                const parsedResults: Array<MassOnboardingEntry> = parsed
                    .filter(entry => entry.join().length !== 0) //filter empty entries
                    .map(entry => {
                        return {
                            domain: entry[0],
                            responsible: entry[1],
                            realms: [
                                { name: entry[2] ? entry[2] : "imported" },
                            ],
                        }; //map entries to MassOnboardingEntry
                    });

                resolve(parsedResults);
            },
        });
    });
}

export function parseRulesList(rules: File): Promise<RedirectEntry[]> {
    return new Promise((resolve, reject) => {
        Papa.parse(rules, {
            delimiter: ";",
            complete: (results: ParseResult<string[]>) => {
                const parsed: string[][] = results.data;

                // using lodash array functions and chaining here
                // for the groupBy implementation
                const parsedResults: RedirectEntry[] = _.chain(parsed)
                    .filter(entry => entry.join().length !== 0)
                    .groupBy(n => n[0])
                    .map(redirectRules => {
                        //map everything to redirect entries with repeating rules
                        const redirectEntry: RedirectEntry = {
                            domain: redirectRules[0][0],
                            country: "*",
                            language: "*",
                            operation: "create",
                            rules: [],
                        };

                        redirectRules.forEach(rule =>
                            redirectEntry.rules.push({
                                matcher: rule[1],
                                target: rule[2],
                            }),
                        );

                        return redirectEntry;
                    })
                    .value();

                resolve(parsedResults);
            },
        });
    });
}
