import { RedirectEntry } from "@/model/redirect";
import { Entity } from "./hateoas";
import { Rule } from "./rules";

export interface RedirectEntryDTO extends Entity {
    name: string;
    operation?: string;
    country_language: string; // country#language // '*' for wildcard
    rules: Rule[];
}

export function redirectEntryFromDTO(dto: RedirectEntryDTO): RedirectEntry {
    const [country, language] = dto.country_language.split("#", 2);
    return {
        domain: dto.name,
        country,
        language,
        rules: dto.rules,
    };
}
