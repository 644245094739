
    import { computed, defineComponent, onMounted, ref, Ref } from "vue";
    import getDomainFromPath from "@/util/getDomainFromPath";
    import { RedirectEntryDetailsDTO, RedirectEntryDTO } from "@/model/dto/rules";
    import * as service from "@/service/rules";
    import { getDomain } from "@/service/domains";
    import { postMassRuleUpdate } from "@/service/redirect";
    import { COUNTRY_CODES, LANGUAGE_CODES } from "@/resources/countrylanguage";
    import { Collection } from "@/model/dto/hateoas";
    import { useToast } from "primevue/usetoast";
    import { useConfirm } from "primevue/useconfirm";
    import { countryName, languageName } from "@/util/i18n";
    import { sortString, sortValueFist } from "@/util/sort";
    import {
        EditRuleOptions,
        InsertRule,
        onCellEditCancel,
        onCellEditInit,
        toViewEntryList,
        useCellEditComplete,
        useContextMenuModel,
        ViewEntry,
    } from "@/composables/RulesView";
    import BackButton from "@/components/BackButton.vue";

    export default defineComponent({
        name: "RulesView",
        components: {
            BackButton,
        },
        setup() {
            const entries: Ref<ViewEntry[]> = ref([]);
            const domainName = getDomainFromPath();
            const entryList: Ref<Collection<RedirectEntryDTO> | null> = ref(null);
            const countries = ref(
                COUNTRY_CODES.map(code => {
                    return { code, name: `${countryName(code)} (${code})` };
                }).sort(
                    sortValueFist(
                        i => i.code,
                        "*",
                        sortString(i => i.name),
                    ),
                ),
            );
            const languages = ref(
                LANGUAGE_CODES.map(code => {
                    return { code, name: `${languageName(code)} (${code})` };
                }).sort(
                    sortValueFist(
                        i => i.code,
                        "*",
                        sortString(i => i.name),
                    ),
                ),
            );
            const isEditingPermitted = ref(false);

            const loading: Ref<boolean> = ref(true);
            const error: Ref<boolean> = ref(false);

            const groups = ["ruleSetKey", "ruleSetLKey"];

            const newCountry = ref("");
            const newLanguage = ref("");

            const editMode = computed(() => {
                return isEditingPermitted.value ? "cell" : "none";
            });
            const contextRule: Ref<ViewEntry | null> = ref(null);
            const selectedRules: Ref<ViewEntry | null> = ref(null);

            const contextMenu = ref();

            const copiedOrCutOutRules: Ref<ViewEntry | null> = ref(null);
            let cutOut = false;

            const toast = useToast();
            const confirm = useConfirm();

            // setup
            const onValueChange = (value: ViewEntry[]) => {
                entries.value = value;
            };

            const rowClass = (rule: ViewEntry) => {
                return copiedOrCutOutRules.value === rule && cutOut ? "cutOut" : "";
            };

            const csvConsumer = postMassRuleUpdate;

            // API call
            const getEntries = async () => {
                loading.value = true;
                try {
                    entryList.value = await getDomain(domainName.value).then(d =>
                        service.getRules(d),
                    );
                    isEditingPermitted.value = entryList.value?._links?.add?.href !== undefined;
                    entries.value = toViewEntryList(
                        entryList.value?._embedded?.content || [],
                        isEditingPermitted.value,
                    );
                } catch (e: any) {
                    console.error("Error loading rules: ", e);
                    error.value = true;
                }

                loading.value = false;
            };

            onMounted(getEntries);

            // API call
            const doUpdateRuleSet = async (ruleSet: RedirectEntryDTO, fetchEntries: boolean) => {
                loading.value = true;
                try {
                    const update = await service.updateRule(ruleSet);
                    if (fetchEntries) {
                        await getEntries();
                    } else {
                        ruleSet.version = update.version;
                    }
                } catch (e: any) {
                    console.error("Error update rule set:  ", e);
                    toast.add({
                        severity: "error",
                        summary: `Could not update rule set`,
                        detail: e.response.data,
                        life: 10000,
                    });
                }
                loading.value = false;
            };

            // API Call
            const doDeleteRuleSet = async (ruleSet: RedirectEntryDTO) => {
                loading.value = true;
                try {
                    await service.deleteRule(ruleSet);
                    await getEntries();
                } catch (e: any) {
                    console.error("Error deleting rule set: ", e);
                    toast.add({
                        severity: "error",
                        summary: `Could not delete rule set`,
                        detail: e.response.data,
                        life: 10000,
                    });
                }
                loading.value = false;
            };

            //API Call
            const doAddRuleSet = async (ruleSet: RedirectEntryDetailsDTO) => {
                if (entryList.value) {
                    loading.value = true;
                    try {
                        await service.createRuleSet(entryList.value._links.add.href, ruleSet);
                        await getEntries();
                    } catch (e: any) {
                        console.error("Error creating rule set: ", e);
                        toast.add({
                            severity: "error",
                            summary: `Could not create rule set`,
                            detail: e.response.data,
                            life: 10000,
                        });
                        loading.value = false;
                    }
                }
            };

            // collection shuffle and API call
            const moveRuleUp = async (rule: ViewEntry | null) => {
                console.log("move up", rule);
                if (!rule) {
                    return;
                }
                const rules = rule.ruleSet.rules;
                const indexInRules = rule.ruleIndex;

                const toSwitch = rules[indexInRules - 1];
                rules[indexInRules - 1] = rules[indexInRules];
                rules[indexInRules] = toSwitch;

                const ruleToSwitch = entries.value[rule.rowIndex - 1].rule;
                entries.value[rule.rowIndex - 1].rule = entries.value[rule.rowIndex].rule;
                entries.value[rule.rowIndex].rule = ruleToSwitch;

                await doUpdateRuleSet(rule.ruleSet, false);
            };

            // collection shuffle and API call
            const moveRuleDown = async (rule: ViewEntry | null) => {
                console.log("move down", rule);
                if (!rule) {
                    return;
                }
                const rules = rule.ruleSet.rules;
                const indexInRules = rule.ruleIndex;

                const toSwitch = rules[indexInRules + 1];
                rules[indexInRules + 1] = rules[indexInRules];
                rules[indexInRules] = toSwitch;

                const ruleToSwitch = entries.value[rule.rowIndex + 1].rule;
                entries.value[rule.rowIndex + 1].rule = entries.value[rule.rowIndex].rule;
                entries.value[rule.rowIndex].rule = ruleToSwitch;

                await doUpdateRuleSet(rule.ruleSet, false);
            };

            // API call
            const deleteRule = async (rule: ViewEntry | null) => {
                console.log("delete", rule);
                if (!rule) {
                    return;
                }
                confirm.require({
                    message: `Are you sure you want to delete this rule?\n\n${rule.rule.matcher} \u279D ${rule.rule.target}`,
                    header: "Confirmation",
                    icon: "pi pi-exclamation-triangle",
                    acceptIcon: "pi pi-trash",
                    acceptClass: "p-button-danger",
                    accept: async () => {
                        try {
                            const rules = rule.ruleSet.rules;
                            const indexInRules = rule.ruleIndex;
                            rules.splice(indexInRules, 1);
                            await doUpdateRuleSet(rule.ruleSet, true);

                            toast.add({
                                severity: "success",
                                summary: "Deleted",
                                detail: `Deleted a rule.`,
                                life: 3000,
                            });
                        } catch (e: any) {
                            console.error("Error deleting domain: ", e);
                            toast.add({
                                severity: "error",
                                summary: `Could not delete this rule`,
                                detail: e.response.data,
                                life: 10000,
                            });
                        }
                    },
                });
            };

            // API call
            const deleteRuleSet = (rule: ViewEntry | null) => {
                console.log("delete rule set", rule);
                if (!rule) {
                    return;
                }
                confirm.require({
                    message: `Are you sure you want to delete this ruleset?\n\n${rule.ruleSet.country}/${rule.ruleSet.language}`,
                    header: "Confirmation",
                    icon: "pi pi-exclamation-triangle",
                    acceptIcon: "pi pi-trash",
                    acceptClass: "p-button-danger",
                    accept: async () => {
                        try {
                            await doDeleteRuleSet(rule.ruleSet);

                            toast.add({
                                severity: "success",
                                summary: "Deleted",
                                detail: `Deleted a ruleset.`,
                                life: 3000,
                            });
                        } catch (e: any) {
                            console.error("Error deleting rule set: ", e);
                            toast.add({
                                severity: "error",
                                summary: `Could not delete this ruleset`,
                                detail: e.response.data,
                                life: 10000,
                            });
                        }
                    },
                });
            };

            const copyCutRule = (rule: ViewEntry | null, option: EditRuleOptions) => {
                if (!rule) {
                    return;
                }
                cutOut = EditRuleOptions.CUT === option;
                copiedOrCutOutRules.value = rule;
            };

            // API call
            const insertRule = async (rule: ViewEntry | null, position: InsertRule) => {
                if (!rule || !copiedOrCutOutRules.value?.rule) {
                    return;
                }
                const insertedRule = copiedOrCutOutRules.value;

                if (cutOut) {
                    const rules = copiedOrCutOutRules.value.ruleSet.rules;
                    const indexInRules = copiedOrCutOutRules.value.ruleIndex;
                    rules.splice(indexInRules, 1);

                    await doUpdateRuleSet(copiedOrCutOutRules.value.ruleSet, false);
                    copiedOrCutOutRules.value = null;
                    cutOut = false;
                }

                const insertIndex =
                    InsertRule.AFTER === position ? rule.ruleIndex + 1 : rule.ruleIndex;
                rule.ruleSet.rules.splice(insertIndex, 0, insertedRule.rule);

                await doUpdateRuleSet(rule.ruleSet, true);
            };

            // API call shortcut
            const addNewRuleSet = () => {
                doAddRuleSet({
                    country: newCountry.value,
                    language: newLanguage.value,
                    version: 0,
                    rules: [],
                });
                newCountry.value = "";
                newLanguage.value = "";
            };

            const isNewRuleSetValid: Ref<boolean> = computed(() => {
                if (!isEditingPermitted.value) {
                    return false;
                }
                const newC = newCountry.value;
                const newL = newLanguage.value;

                if (newC === "" || newL === "") {
                    return false;
                }
                return !entryList.value?._embedded?.content.find(
                    el => el.country === newC && el.language === newL,
                );
            });

            // context menu
            const onRowContextMenu = (event: any) => {
                contextMenu.value.show(event.originalEvent);
            };
            // context menu config and wiring
            const menuModel = useContextMenuModel(
                contextRule,
                isEditingPermitted,
                copiedOrCutOutRules,
                moveRuleUp,
                moveRuleDown,
                deleteRule,
                deleteRuleSet,
                copyCutRule,
                insertRule,
            );

            // cell editing
            const onCellEditComplete = useCellEditComplete(doUpdateRuleSet);

            return {
                domainName,
                entries,
                onValueChange,
                rowClass,
                groups,
                csvConsumer,
                countries,
                languages,
                newLanguage,
                newCountry,
                isNewRuleSetValid,
                isEditingPermitted,
                addNewRuleSet,
                contextMenu,
                onRowContextMenu,
                editMode,
                onCellEditInit,
                onCellEditComplete,
                onCellEditCancel,
                menuModel,
                contextRule,
                selectedRules,
                countryName,
                languageName,
                loading,
                error,
            };
        },
    });
