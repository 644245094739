export const COUNTRY_CODES = [
    "*",
    "AF",
    "AX",
    "AL",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "AZ",
    "BS",
    "BH",
    "BD",
    "BB",
    "BY",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BQ",
    "BA",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "BI",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "CF",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CG",
    "CD",
    "CK",
    "CR",
    "CI",
    "HR",
    "CU",
    "CW",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "ER",
    "EE",
    "ET",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GW",
    "GY",
    "HT",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IE",
    "IM",
    "IL",
    "IT",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "XK",
    "KP",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LB",
    "LS",
    "LR",
    "LY",
    "LI",
    "LT",
    "LU",
    "MO",
    "MK",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "MX",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "MM",
    "NA",
    "NR",
    "NP",
    "NL",
    "NC",
    "NZ",
    "NI",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PS",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RU",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SX",
    "SK",
    "SI",
    "SB",
    "SO",
    "ZA",
    "GS",
    "SS",
    "ES",
    "LK",
    "SD",
    "SR",
    "SJ",
    "SZ",
    "SE",
    "CH",
    "SY",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TN",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "UA",
    "AE",
    "GB",
    "US",
    "UM",
    "UY",
    "UZ",
    "VU",
    "VE",
    "VN",
    "VG",
    "VI",
    "WF",
    "EH",
    "YE",
    "ZM",
    "ZW",
];

export const LANGUAGE_CODES = [
    "*",
    "af",
    "sq",
    "ar",
    "ar-DZ",
    "ar-BH",
    "ar-EG",
    "ar-IQ",
    "ar-JO",
    "ar-KW",
    "ar-LB",
    "ar-LY",
    "ar-MA",
    "ar-OM",
    "ar-QA",
    "ar-SA",
    "ar-SY",
    "ar-TN",
    "ar-AE",
    "ar-YE",
    "an",
    "hy",
    "as",
    "ast",
    "az",
    "eu",
    "bg",
    "be",
    "bn",
    "bs",
    "br",
    "my",
    "ca",
    "ch",
    "ce",
    "zh",
    "zh-HK",
    "zh-CN",
    "zh-SG",
    "zh-TW",
    "cv",
    "co",
    "cr",
    "hr",
    "cs",
    "da",
    "nl",
    "nl-BE",
    "en",
    "en-AU",
    "en-BZ",
    "en-CA",
    "en-IE",
    "en-JM",
    "en-NZ",
    "en-PH",
    "en-ZA",
    "en-TT",
    "en-GB",
    "en-US",
    "en-ZW",
    "eo",
    "et",
    "fo",
    "fj",
    "fi",
    "fr",
    "fr-BE",
    "fr-CA",
    "fr-FR",
    "fr-LU",
    "fr-MC",
    "fr-CH",
    "fy",
    "fur",
    "gd-IE",
    "gl",
    "ka",
    "de",
    "de-AT",
    "de-DE",
    "de-LI",
    "de-LU",
    "de-CH",
    "el",
    "gu",
    "ht",
    "he",
    "hi",
    "hu",
    "is",
    "id",
    "iu",
    "ga",
    "it",
    "it-CH",
    "ja",
    "kn",
    "ks",
    "kk",
    "km",
    "ky",
    "tlh",
    "ko",
    "ko-KP",
    "ko-KR",
    "la",
    "lv",
    "lt",
    "lb",
    "mk",
    "ms",
    "ml",
    "mt",
    "mi",
    "mr",
    "mo",
    "nv",
    "ng",
    "ne",
    "no",
    "nb",
    "nn",
    "oc",
    "or",
    "om",
    "fa",
    "fa-IR",
    "pl",
    "pt",
    "pt-BR",
    "pa",
    "pa-IN",
    "pa-PK",
    "qu",
    "rm",
    "ro",
    "ro-MO",
    "ru",
    "ru-MO",
    "sz",
    "sg",
    "sa",
    "sc",
    "gd",
    "sd",
    "si",
    "sr",
    "sk",
    "sl",
    "so",
    "sb",
    "es",
    "es-AR",
    "es-BO",
    "es-CL",
    "es-CO",
    "es-CR",
    "es-DO",
    "es-EC",
    "es-SV",
    "es-GT",
    "es-HN",
    "es-MX",
    "es-NI",
    "es-PA",
    "es-PY",
    "es-PE",
    "es-PR",
    "es-ES",
    "es-UY",
    "es-VE",
    "sx",
    "sw",
    "sv",
    "sv-FI",
    "sv-SV",
    "ta",
    "tt",
    "te",
    "th",
    "tig",
    "ts",
    "tn",
    "tr",
    "tk",
    "uk",
    "hsb",
    "ur",
    "ve",
    "vi",
    "vo",
    "wa",
    "cy",
    "xh",
    "ji",
    "zu",
];
