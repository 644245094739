import { RedirectEntryDTO, Rule } from "@/model/dto/rules";

export interface ViewEntry {
    rowIndex: number;
    ruleIndex: number;
    ruleSetKey: string;
    ruleSetLKey: string;
    ruleSet: RedirectEntryDTO;
    rule: Rule;
    newRule: boolean;
}

export enum EditRuleOptions {
    CUT,
    COPY,
}

export enum InsertRule {
    AFTER,
    BEFORE,
}

export function toViewEntryList(
    ruleSets: RedirectEntryDTO[],
    isEditing: boolean,
): ViewEntry[] {
    const list = ruleSets
        .flatMap(ruleSet => mapRuleSet(ruleSet, isEditing))
        .sort((a, b) => a.ruleSetKey.localeCompare(b.ruleSetKey));
    list.forEach((row, index) => (row.rowIndex = index));
    return list;
}

function mapRuleSet(
    ruleSet: RedirectEntryDTO,
    isEditing: boolean,
): ViewEntry[] {
    const list = ruleSet.rules.map((rule, ruleIndex) =>
        viewEntry(ruleSet, rule, ruleIndex),
    );
    if (isEditing) {
        list.push({
            rowIndex: 0,
            ruleIndex: 0,
            ruleSetKey: getKey(ruleSet),
            ruleSetLKey: getLKey(ruleSet),
            ruleSet,
            rule: { matcher: "", target: "" },
            newRule: true,
        });
    }
    return list;
}

function viewEntry(
    ruleSet: RedirectEntryDTO,
    rule: Rule,
    ruleIndex: number,
): ViewEntry {
    return {
        rowIndex: 0,
        ruleIndex,
        ruleSetKey: getKey(ruleSet),
        ruleSetLKey: getLKey(ruleSet),
        ruleSet,
        rule: { ...rule },
        newRule: false,
    };
}

function getKey(ruleSet: RedirectEntryDTO) {
    return ruleSet.country + "#" + ruleSet.language;
}

function getLKey(ruleSet: RedirectEntryDTO) {
    return ruleSet.language + "#" + ruleSet.country;
}
