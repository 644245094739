import axios, { axiosOptions } from "@/service/axios";
import { Collection } from "@/model/dto/hateoas";
import { RedirectEntryDetailsDTO, RedirectEntryDTO } from "@/model/dto/rules";
import { DomainStatusDTO } from "@/model/dto/domain_v2";

export async function getRules(
    domain: DomainStatusDTO,
): Promise<Collection<RedirectEntryDTO>> {
    const dtoList = await axios.get<Collection<RedirectEntryDTO>>(
        domain._links.rules.href,
        await axiosOptions(),
    );
    return dtoList.data;
}

export async function getRule(
    rule: RedirectEntryDTO,
): Promise<RedirectEntryDTO> {
    const dtoList = await axios.get<RedirectEntryDTO>(
        rule._links.self.href,
        await axiosOptions(),
    );
    return dtoList.data;
}

export async function createRuleSet(
    link: string,
    data: RedirectEntryDetailsDTO,
): Promise<RedirectEntryDTO> {
    const dto = await axios.post<RedirectEntryDTO>(
        link,
        data,
        await axiosOptions(),
    );
    return dto.data;
}

export async function updateRule(
    data: RedirectEntryDTO,
): Promise<RedirectEntryDTO> {
    console.log("Update rule: ", data._links.update.href);
    const dto = await axios.put<RedirectEntryDTO>(
        data._links.update.href,
        data,
        await axiosOptions(),
    );
    return dto.data;
}

export async function deleteRule(data: RedirectEntryDTO) {
    await axios.delete<String>(data._links.delete.href, await axiosOptions());
}
