import { Rule } from "@/model/dto/rules";

const isRuleValid = (rule: Rule) => {
    return rule.matcher !== "" && rule.target !== "";
};

export const onCellEditInit = (event: any) => {};

export const onCellEditCancel = (event: any) => {
    const type = event.type;
    const rule = event.data;
    if (rule.newRule) {
        rule.rule.matcher = "";
        rule.rule.target = "";
    } else {
        // rule always needs to be a copy of the entry in ruleSet
        rule.rule = { ...rule.ruleSet.rules[rule.ruleIndex] };
    }
};

export function useCellEditComplete(doUpdateRuleSet: Function) {
    return async (event: any) => {
        const rule = event.data;
        const newRule = {
            matcher: rule.rule.matcher.trim(),
            target: rule.rule.target.trim(),
        };
        if (!isRuleValid(newRule)) {
            // don't save invalid rules and revert
            if (!rule.newRule) {
                // rule always needs to be a copy of the entry in ruleSet
                rule.rule = { ...rule.ruleSet.rules[rule.ruleIndex] };
            }
            return;
        }
        if (rule.newRule) {
            const rules = rule.ruleSet.rules;
            rules.push(newRule);
        } else {
            const oldRule = rule.ruleSet.rules[rule.ruleIndex];
            if (
                oldRule.matcher === newRule.matcher &&
                oldRule.target === newRule.target
            ) {
                // unchanged
                rule.rule = { ...oldRule }; // rule always needs to be a copy of the entry in ruleSet
                return;
            }
            // rule always needs to be a copy of the entry in ruleSet
            rule.rule = { ...newRule };
            rule.ruleSet.rules[rule.ruleIndex] = newRule;
        }
        console.log("complete update", rule.ruleSet, rule.newRule);
        await doUpdateRuleSet(rule.ruleSet, rule.newRule);
    };
}
