import axios, { axiosOptions } from "@/service/axios";
import { RedirectEntry } from "@/model/redirect";
import { RedirectEntryDTO, redirectEntryFromDTO } from "@/model/dto/redirect";

import { parseRulesList } from "@/service/CSVParser";

export async function putDataByDomain(
    domain: string,
    body: RedirectEntry[],
): Promise<RedirectEntry[]> {
    const dtoList = await axios.put<RedirectEntryDTO[]>(
        `/domains/${domain}/rules`,
        body,
        await axiosOptions(),
    );
    return dtoList.data.map(redirectEntryFromDTO);
}

export async function postMassRuleUpdate(csv: File): Promise<RedirectEntry[]> {
    const parsed: RedirectEntry[] = await parseRulesList(csv);

    const requestQueue: Promise<RedirectEntry[]>[] = [];

    parsed.forEach(entry => {
        if (entry.domain.length > 0)
            requestQueue.push(putDataByDomain(entry.domain, [entry]));
    });

    return new Promise((resolve, reject) => {
        Promise.all(requestQueue)
            .then(values => {
                resolve(values.flat());
            })
            .catch(e => reject(e));
    });
}
