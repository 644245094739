export function sortString<T>(key: (item: T) => string) {
    return (a: T, b: T) => {
        return key(a).localeCompare(key(b));
    };
}

export function sortValueFist<T>(
    key: (item: T) => string,
    value: string,
    then: (a: T, b: T) => number,
) {
    return (a: T, b: T) => {
        const aKey = key(a);
        const bKey = key(b);

        if (aKey === value) {
            return bKey === value ? 0 : -1;
        }
        if (bKey === value) {
            return 1;
        }
        return then(a, b);
    };
}
