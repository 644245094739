import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "add-ruleset"
}
const _hoisted_5 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_prime_column = _resolveComponent("prime-column")!
  const _component_prime_inputtext = _resolveComponent("prime-inputtext")!
  const _component_prime_datatable = _resolveComponent("prime-datatable")!
  const _component_prime_dropdown = _resolveComponent("prime-dropdown")!
  const _component_prime_button = _resolveComponent("prime-button")!
  const _component_prime_message = _resolveComponent("prime-message")!
  const _component_prime_contextMenu = _resolveComponent("prime-contextMenu")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BackButton),
    _cache[6] || (_cache[6] = _createElementVNode("h1", null, "RulesView", -1)),
    (_ctx.entries && !_ctx.error)
      ? (_openBlock(), _createBlock(_component_prime_datatable, {
          key: 0,
          value: _ctx.entries,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entries) = $event)),
          contextMenuSelection: _ctx.contextRule,
          "onUpdate:contextMenuSelection": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contextRule) = $event)),
          selection: _ctx.selectedRules,
          "onUpdate:selection": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedRules) = $event)),
          class: "domain-info",
          loading: _ctx.loading,
          "row-class": _ctx.rowClass,
          "sort-field": "ruleSetKey",
          "sort-order": 1,
          "edit-mode": _ctx.editMode,
          "row-group-mode": "rowspan",
          "group-rows-by": _ctx.groups,
          "selection-mode": "multiple",
          contextmenu: "",
          onCellEditInit: _ctx.onCellEditInit,
          onCellEditComplete: _ctx.onCellEditComplete,
          onCellEditCancel: _ctx.onCellEditCancel,
          onRowContextmenu: _ctx.onRowContextMenu,
          onValueChange: _ctx.onValueChange
        }, {
          header: _withCtx(() => [
            _createElementVNode("h2", null, _toDisplayString(_ctx.domainName), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_prime_column, {
              field: "ruleSetKey",
              header: "Country",
              sortable: true,
              class: "country",
              "header-style": "width: 10%"
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.countryName(slotProps.data.ruleSet.country) +
                    " (" +
                    slotProps.data.ruleSet.country +
                    ")"), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_prime_column, {
              field: "ruleSetLKey",
              header: "Language",
              sortable: true,
              class: "language",
              "header-style": "width: 10%"
            }, {
              body: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.languageName(slotProps.data.ruleSet.language) +
                    " (" +
                    slotProps.data.ruleSet.language +
                    ")"), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_prime_column, {
              field: "rule.matcher",
              header: "Matcher"
            }, {
              body: _withCtx((slotProps) => [
                (slotProps.data.newRule)
                  ? (_openBlock(), _createBlock(_component_prime_inputtext, {
                      key: 0,
                      modelValue: slotProps.data.rule.matcher,
                      "onUpdate:modelValue": ($event: any) => ((slotProps.data.rule.matcher) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(slotProps.data.rule.matcher), 1))
              ]),
              editor: _withCtx((slotProps) => [
                _withDirectives(_createVNode(_component_prime_inputtext, {
                  modelValue: slotProps.data.rule.matcher,
                  "onUpdate:modelValue": ($event: any) => ((slotProps.data.rule.matcher) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"]), [
                  [_directive_focus]
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_prime_column, {
              field: "rule.target",
              header: "Target"
            }, {
              body: _withCtx((slotProps) => [
                (slotProps.data.newRule)
                  ? (_openBlock(), _createBlock(_component_prime_inputtext, {
                      key: 0,
                      modelValue: slotProps.data.rule.target,
                      "onUpdate:modelValue": ($event: any) => ((slotProps.data.rule.target) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(slotProps.data.rule.target), 1))
              ]),
              editor: _withCtx((slotProps) => [
                _withDirectives(_createVNode(_component_prime_inputtext, {
                  modelValue: slotProps.data.rule.target,
                  "onUpdate:modelValue": ($event: any) => ((slotProps.data.rule.target) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"]), [
                  [_directive_focus]
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "contextMenuSelection", "selection", "loading", "row-class", "edit-mode", "group-rows-by", "onCellEditInit", "onCellEditComplete", "onCellEditCancel", "onRowContextmenu", "onValueChange"]))
      : _createCommentVNode("", true),
    (!_ctx.error)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_3, "Add Ruleset"))
      : _createCommentVNode("", true),
    (!_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _createVNode(_component_prime_dropdown, {
            modelValue: _ctx.newCountry,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newCountry) = $event)),
            options: _ctx.countries,
            "option-label": "name",
            "option-value": "code",
            class: "new-country",
            placeholder: "Country"
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_prime_dropdown, {
            modelValue: _ctx.newLanguage,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newLanguage) = $event)),
            options: _ctx.languages,
            "option-label": "name",
            "option-value": "code",
            class: "new-language",
            placeholder: "Language"
          }, null, 8, ["modelValue", "options"]),
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_prime_button, {
              disabled: !_ctx.isNewRuleSetValid,
              label: "Add",
              class: "add-ruleset-button",
              onClick: _ctx.addNewRuleSet
            }, null, 8, ["disabled", "onClick"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_prime_message, {
          key: 3,
          severity: "error",
          closable: false
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" Error loading rules, please try again ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_prime_contextMenu, {
      ref: "contextMenu",
      model: _ctx.menuModel
    }, null, 8, ["model"])
  ], 64))
}